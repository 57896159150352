

.footer-text {
    color: white;
}

.footer-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
}