.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container fills the viewport height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'kufam';
  src: url('./assets/fonts/Kufam-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kufam';
  src: url(./assets/fonts/Kufam-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: 'kufam', sans-serif;
}

.bg-neat-color-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(214 250 89 / var(--tw-bg-opacity, 1));
}

.bg-neat-color-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(64 66 255 / var(--tw-bg-opacity, 1));
}
/* Ensure the app container fills the viewport */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
