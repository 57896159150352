.hero-section {
    height: calc(100vh - 20px);
    position: relative;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    overflow: hidden;
    z-index: 1;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Black overlay with 50% opacity */
    z-index: 1;
    /* Ensures it sits above the background image but below the content */
}