.services-section {
    background: #d6fa59;
    padding: 0px 20px;
    text-align: center;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    overflow: hidden;
    z-index: 2;
    margin-top: -1.5%;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
    /* height: 100vh; */
    

  }
  
  .services-heading {
    font-size: 3.5rem;
    font-weight: normal;
    color: #000;
    margin-bottom: 40px;
    text-align: right;
    padding-right: 20px; /* Optional: Add padding for better spacing */
    
  }
  
  .services-grid {
    display: flex; /* Makes the grid horizontal */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    justify-content: center; /* Centers the cards horizontally */
    align-items: flex-start; /* Aligns the cards to the top */
    gap: 1.5%; /* Adds spacing between the cards */
    padding-bottom: 2%;
    min-height: 50vh;
  }

  @media (max-width: 850px) {
    .services-grid {
      flex-direction: column; /* Change layout to vertical */
      align-items: center; /* Center-align the cards */
      gap: 20px;
    }
  
    .service-card {
      width: 90%; /* Make each card take up most of the width */
      max-width: none; /* Remove max-width */
      height: auto; /* Adjust height for content */
    }
  }