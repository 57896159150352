/* General Styles */
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  z-index: 1000;
  padding: 1rem 0rem;
  transition: all 0.3s ease;
  background: transparent;
}

.navbar.shrink {
  padding: 10px 20px; /* Reduce padding for shrink effect */
  height: 60px; /* Adjust navbar height */
}

.logo {
  height: 40px;
  transition: height 0.3s ease;
}

.navbar.shrink .logo {
  height: 30px; /* Smaller logo size on scroll */
}

.hamburger-menu {
  transition: transform 0.3s ease;
}

.navbar.shrink .hamburger-menu {
  transform: scale(0.8); /* Shrink hamburger icon */
}

.hamburger-menu {
  margin-right: 5%;
}

.logo {
  height: 60px;
  margin-left: 5%;
}

/* Hero section adjustments */
.hero-section {
  height: calc(100vh - 20px);
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Black overlay with 50% opacity */
  z-index: 1;
  /* Ensures it sits above the background image but below the content */
}

.hero-content {
  position: absolute;
  max-width: 600px;
  text-align: left;
  left: 5%;
  top: 40%;
  transform: translateY(-40%);
  z-index: 2;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.hero-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
  background-color: #d6fa59;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.hero-button:hover {
  background-color: #45a049;
}

.hero-title {
  color: #d6fa59;
  font-size: 4rem !important;
}

.hero-text {
  font-size: 2rem !important;
}

/* Menu Links Container */
.menu-links {
  position: fixed;
  top: 60px; /* Adjust based on your navbar height */
  right: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Menu Links List */
.menu-links ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

/* Menu Links Items */
.menu-links ul li {
  padding: 10px 20px;
}

.menu-links ul li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.menu-links ul li a:hover {
  color: #007bff;
}

.text-neat-blue {
  --tw-text-opacity: 1;
  color: rgb(64 66 255 rgb / var(--tw-bg-opacity, 1));
}

.text-neat-yellow {
  --tw-text-opacity: 1;
  color: rgb(214 250 89 / var(--tw-bg-opacity, 1));
}
