.find-us-container {
    display: flex;
    flex-direction: row;
    height: 100vh; /* Full viewport height */
    background-color: #000000;
  }
  
  .map-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2%; /* Adjust spacing */
}
  
  .map-container iframe {
    width: 100%; /* Width of the map */
    max-height: 1500px; /* Height of the map (square shape) */
    aspect-ratio: 1 / 1;
    border: none;
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow */
  }

  @media (max-width: 768px) {
    .map-container iframe {
      width: 90%; /* Reduce size for smaller screens */
      aspect-ratio: 1 / 1; /* Maintain square shape */
    }
  }
  
  .content-container {
    flex: 1;
    /* background-color: #000000; */
    padding: 40px;
    padding-left: 6%;
    padding-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: flex-start;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .find-us-title {
    font-size: 4rem;
    margin-bottom: 20px;
    color: #d6fa59;
  }
  
  .address-text {
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 1.5;
    color: #d6fa59;
    margin: 0;
  }

  .text-container {
    display: flex;
    flex-direction: column; /* Stack heading and text */
    align-items: flex-start; /* Align content to the left */
    gap: 10px;
}
  
  .left-button {
    display: inline-block; /* Prevent the button from stretching */
    background-color: #d6fa59; /* Your button color */
    color: #000; /* Text color */
    padding: 10px 20px; /* Add padding to the button */
    border: none; /* Remove default border */
    border-radius: 20px; /* Optional: Rounded corners */
    cursor: pointer;
    text-align: center; /* Center the text inside the button */
    transition: all 0.3s ease; /* Smooth hover effect */
    font-weight: bold;
  }
  
  .left-button:hover {
    background-color: #c0e756; /* Slightly darker hover effect */
  }

.title-and-button {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: flex-start; /* Align content to the left */
    margin-bottom: 100px;
    width: auto; /* Don't force 100% width */
}

  .address-text {
    margin-bottom: 10px;
  }


  .address-icon {
    width: 80px; /* Adjust size of the icon */
    height: 80px;
    fill: #d6fa59;
}

  .address-heading {
    font-size: 1.5rem; /* Adjust the size of the heading */
    font-weight: bold;
    color: #d6fa59; /* Optional: Match theme color */
    margin: 0;
}

.addresses-container {
    display: flex;
    align-items: center; /* Align icon and text vertically */
    gap: 5px; /* Space between icon and text */
    margin-top: 20px;
}

.address-content {
    display: flex; /* Use flexbox to align text and icon horizontally */
    align-items: center; /* Vertically center align text and icon */
    gap: 10px; /* Add spacing between text and icon */
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

