.service-card {
  display: grid;
  position: relative;
  color: #fff;
  border-radius: 15px;
  flex: 1 1 calc(33.33% - 20px);
  max-height: 600px; /* Adjust height as needed */
  max-width: 600px; /* Optional: Prevent it from getting too large */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  background-size: cover;
  background-position: center;
  aspect-ratio: 1 / 1;
}

.service-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Overlay layer */
.service-card::before {
  content: ""; /* Empty content to act as overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  z-index: 1; /* Sits below the content */
}

.service-content {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icon {
  max-width: 75px;
  height: auto;
  fill: #d6fa59; /* Adjust the color of SVG icons */
  margin-bottom: 10px;
}

.service-title {
  font-size: 1.5rem;
  margin: 0;
  font-weight: bold;
  color:#d6fa59;
}

.service-description {
  font-size: 0.9rem;
  margin-top: 10px;
  line-height: 1.4;
}

.service-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: #d6fa59;
  color: #000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s ease;
}

.service-button:hover {
  background: #c4ea50; /* Slightly darker green on hover */
}